<script setup>
import CustomerNavigation from '@/Pages/Customer/Navigation.vue';

import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import DarkMode from '@/Components/DarkMode.vue';
import SwitchLocale from '@/Components/SwitchLocale.vue';

import { ref } from 'vue';

const props = defineProps({
    nav: { type: Boolean, default: true },
    nav_pos_absolute: { type: Boolean, default: true },
    nav_as_hamburger: { type: Boolean, default: true },
    //
    layout: { type: Object },
});

const layout = ref(null);
if (!props.layout) {
    layout.value = window.defaultLayoutObject;
} else {
    layout.value = props.layout;
}
</script>
<template>
    <div class="min-h-full h-screen bg-[#e1f8fb] overflow-x-hidden">

        <CustomerNavigation v-if="nav" :nav_pos_absolute="nav_pos_absolute" :nav_as_hamburger="nav_as_hamburger" />

        <!-- <header class="bg-white dark:bg-[#1D2F33] shadow" v-if="$slots.header">
            <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <slot name="header" />
            </div>
        </header> -->

        <!-- Page Content -->
        <div class="flex items-center justify-center min-h-full bg-[#e1f8fb]">
            <div>
                <main class="mt-8">
                    <slot />
                </main>
            </div>
        </div>

        <!-- Footer -->
        <!-- TODO: Add Switch Locale & Dark Mode to Customer Layout -->
        <div class="flex flex-row-reverse max-w-4xl mx-auto px-4 pb-32 sm:px-6 lg:px-8 text-slate-700 text-sm font-medium dark:text-slate-200">
            <a href="https://www.meditbe.com/" target="_blank" class="hover:text-slate-900 dark:hover:text-white" aria-label="Meditbe.com">
                <ApplicationLogo class="block h-6 w-auto fill-current dark:text-white" />
            </a>
            <div v-if="true || layout?.show_btn_dark_mode || layout?.show_btn_switch_locale" class="w-full flex  text-slate-600">
                <div class="flex gap-3">
                    <DarkMode v-if="true || layout?.show_btn_dark_mode" />
                    <SwitchLocale v-if="true || layout?.show_btn_switch_locale" />
                </div>
            </div>
        </div>
    </div>
</template>